<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="card">
          <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
            <h3 class="font-weight-bolder">
              {{ $t('parcelCode') }}
            </h3>
            <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @keyup.enter="getTracking()"
                    >
                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getTracking"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1 mt-lg-0">
            <router-link
              :to="{name: 'admin-tracking-create'}"
              class="px-2"
            >
              <button
                type="button"
                class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
              >
                <span>+ {{ $t('addTrackingCode') }} </span>

              </button>
            </router-link>
          </div>

          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :total-rows="totalRow"
              :per-page="perPage"
              :items="items"
              :fields="fields"
            >

              <template #cell(detail)="data">
                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('key-22') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <small class="text-black">
                      {{ data.item.tag_id ? data.item.tag_id : '-' }}
                    </small>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('systemOrder') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <b-link
                      v-if="data.item.order_text"
                      :to="{name : 'admin-order-detail', params: {id: data.item.order_id}}"
                      class="mb-0"
                    >
                      {{ `ORD-${data.item.order_text}` }}
                    </b-link>
                    <span v-else>
                      -
                    </span>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('parcelCode') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <b-link
                      :to="{name : 'admin-tracking-edit', params: {id: data.item._id}}"
                      class="mb-0"
                    >
                      {{ data.item.postnumber }}
                    </b-link>
                    <i
                      v-clipboard:copy="data.item.postnumber"
                      :class="iconClass[data.item.postnumber] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                      @click="handleCopy(data.item.postnumber)"
                    />
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('lotSequence') }} :
                    </p>
                  </div>
                  <div
                    v-if="data.item.lot_id"
                    class="w-50"
                  >
                    <p
                      class="mb-0 text-black badge badge-warning"
                      :class="{ 'mb-0 text-black badge badge-danger': data.item.transportType === 2 }"
                    >
                      {{ checktype(data.item.transportType) }}
                    </p>
                    <b-link :to="{name : 'admin-warehouse-edit', params: {id:data.item.lot_name }}">
                      {{ data.item.lot_name ? data.item.lot_name: '-' }}
                    </b-link>
                    <small>/{{ data.item.lot_order ? data.item.lot_order: '-' }}</small>
                  </div>
                  <div
                    v-else
                    class="w-50"
                  >
                    <p class="mb-0">
                      -
                    </p>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('woodPacking') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <span
                      v-if="data.item.packbox"
                      style="font-size: 11px;"
                      class="badge badge-success"
                    >{{ $t('activate') }}</span>
                    <span
                      v-else
                      style="font-size: 11px;"
                      class="badge badge-secondary"
                    >{{ $t('key-328') }}</span>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('checkOrder') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <span
                      v-if="data.item.checkproduct"
                      style="font-size: 11px;"
                      class="badge badge-success"
                    >{{ $t('activate') }}</span>
                    <span
                      v-else
                      style="font-size: 11px;"
                      class="badge badge-secondary"
                    >{{ $t('key-328') }}</span>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('type') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <p class="mb-0">
                      {{ chechproduct(data.item.product_type) }}
                    </p>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('mnemonic') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <p
                      class="mb-0 text-truncate text-left"
                      style="max-width: 200px !important"
                    >
                      {{ data.item.keyword ? data.item.keyword: '-' }}
                    </p>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('remarks') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <p
                      class="mb-0 text-truncate text-left"
                      style="max-width: 200px !important"
                    >
                      {{ data.item.remark ? data.item.remark: '-' }}
                    </p>
                  </div>
                </div>

                <div
                  v-if="data.item.claim_status"
                  class="d-flex text-danger border-top mt-25 pt-25"
                >
                  <div class="w-50">
                    <p class="mb-0 text-right mr-50">
                      {{ $t('key-23') }} :
                    </p>
                  </div>
                  <div class="w-50">
                    <p class="mb-0">
                      {{ data.item.claim_detail ? data.item.claim_detail: '-' }}
                    </p>
                    <p class="mb-0">
                      {{ $t('quantity') }} : {{ data.item.claim_amount ? Commas(data.item.claim_amount): '0' }} ฿
                    </p>
                  </div>
                </div>
              </template>

              <template #cell(customerCode)="data">
                <b-link
                  :to="{name : 'admin-employee-edit', params: {id: data.item.uid} }"
                  class="mb-0"
                >
                  <!-- <span
                    v-if="data.item.notproduct"
                    class="text-danger"
                  >
                    {{ $t('ownerlessGoods') }}
                  </span> -->
                  <span>
                    {{ data.item.username }}
                  </span>
                  <!-- {{ data.item.notproduct }} -->

                </b-link>
              </template>

              <template #cell(calPrice)="data">
                <div class="d-flex">
                  <div class="w-50">
                    <div class="mb-0 text-right mr-50">
                      <small>{{ $t('dimensionsCm') }} :</small>
                    </div>
                  </div>
                  <div class="w-50">
                    <small class="mb-0">
                      ({{ data.item.width }} x {{ data.item.long }} x {{ data.item.height }}) x {{
                        data.item.product_amount }}
                      <!-- (76 x 53 x 26) x 1 -->
                    </small>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <div class="mb-0 text-right mr-50">
                      <small>{{ $t('weightKilo') }} :</small>
                    </div>
                  </div>
                  <div class="w-50">
                    <small class="mb-0">
                      ({{ data.item.weight }}) x {{ data.item.product_amount }}
                      <!-- (3.3) x 1 -->
                    </small>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <div class="mb-0 text-right mr-50">
                      <small>{{ $t('quantity') }} :</small>
                    </div>
                  </div>
                  <div class="w-50">
                    <small class="mb-0">
                      {{ data.item.product_amount }}
                    </small>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="w-50">
                    <div class="mb-0 text-right mr-50">
                      <small>{{ checkcharge_per(data.item) }} :</small>
                    </div>
                  </div>
                  <div class="w-50">
                    <p class="mb-0">
                      {{ Commas(data.item.charge_per_save) }} ฿
                    </p>
                  </div>
                </div>
                <div class="mt-50">
                  <div
                    v-if="data.item.path_img.length"
                    class="d-flex flex-wrap justify-content-center"
                  >
                    <div
                      v-for="url in data.item.path_img"
                      :key="url"
                      class="preview-image-container mr-25 d-flex justify-content-center"
                    >
                      <img
                        :src="GetImg('postnumber_img',url)"
                        alt="Preview"
                        class=""
                        style="max-width: 100%; height: auto;"
                        @click="showPicture(data.item.path_img)"
                      >
                      <!-- <div class="preview-image-overlays p-0">
                        <i
                          class="fas fa-times-circle cursor-pointer"
                          @click="removeImage(url,data.item._id)"
                        />
                      </div> -->
                    </div>
                  </div>

                  <!-- <div class="clrr-setting mt-25 cursor-pointer">
                    <div class="input-file-container cursor-pointer">
                      <i class="fal fa-plus cursor-pointer mr-50" />{{ $t('key-9') }}
                      <input
                        id="img-file"
                        ref="fileInput"
                        type="file"
                        class="input-file"
                        accept="image/*"
                        multiple
                        @change="handleFileChanges($event,data.item._id)"
                      >
                    </div>
                  </div> -->
                </div>

              </template>

              <template #cell(tranStatus)="data">
                <app-timeline>
                  <app-timeline-item
                    v-if="data.item.out_thaiwarehouse"
                    :title="`${data.item.itemnumber_payment? data.item.itemnumber_payment: ''}  ${data.item.out_thaiwarehouse} : นำออกแล้ว`"
                    variant="success"
                  />
                  <app-timeline-item
                    v-if="data.item.paymentday"
                    :title="`${data.item.paymentday} :`+$t('payShipping')"
                    variant="success"
                  />

                  <!-- <app-timeline-item
                  v-if="data.item.tranStatus.payFreight"
                  :title="`${data.item.tranStatus.payFreight} : ชำระค่าขนส่ง`"
                  variant="success"
                /> -->

                  <app-timeline-item
                    v-if="data.item.come_thaiwarehouse"
                    :title="`${data.item.come_thaiwarehouse} : `+$t('arrivedThaiWarehouse')"
                    variant="success"
                  />

                  <app-timeline-item
                    v-if="data.item.out_chinesewarehouse"
                    :title="`${data.item.out_chinesewarehouse} : `+$t('exitWarehouse')"
                    variant="success"
                  />

                  <app-timeline-item
                    v-if="data.item.come_chinesewarehouse"
                    :title="`${data.item.come_chinesewarehouse} : `+$t('arrivedChinaWarehouse')"
                    variant="success"
                  />
                </app-timeline>
              </template>

              <template #cell(setup)="data">
                {{ data.item.collocate_wh_name ? data.item.collocate_wh_name: '-' }}
              </template>

              <template #cell(bill)="data">
                <!-- {{ payment. }} -->
                <!-- {{ getpayment(data.item) }} -->
                <b-link
                  v-if="data.item.id_payment"
                  :to="{name : 'shipment_payment_edit', params: {id: data.item.id_payment}}"
                  class="mb-0"
                >
                  {{ data.item.itemnumber_payment }}
                </b-link>
                <span
                  v-else
                  class="badge badge-warning text-black"
                >
                  {{ $t('none') }}
                </span>
              </template>

              <template #cell(action)="data">
                <feather-icon
                  v-if="data.item.claim_status === false"
                  icon="RepeatIcon"
                  class="mr-50 text-primary cursor-pointer"
                  @click="Claim(data.item)"
                />

                <feather-icon
                  icon="Edit3Icon"
                  class="mr-50 text-primary cursor-pointer"
                  @click="EditType(data.item._id)"
                />

                <feather-icon
                  icon="TrashIcon"
                  class="text-danger cursor-pointer"
                  @click="DeleteData(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRow"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getTracking"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>

        </div>
      </b-overlay>

      <b-modal
        id="modal-cliam"
        :title="$t('key-21')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="submitAddNew()"
      >

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="detail"
            rules="required"
          >
            <label for="claim_detail">{{ $t('details') }} :</label>
            <b-form-textarea
              id="claim_detail"
              v-model="claim_detail"
              :placeholder="$t('details')"
              :state="errors.length > 0 ? false:null"
            />
            <span class="text-danger">{{ errors[0] ? $t('key-24'): '' }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <!-- <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required|positive"
          > -->
            <label for="amount">{{ $t('key-25') }} : <span class="text-danger">*</span></label>
            <b-form-input
              id="Amount"
              v-model="claim_amount"
              type="number"
              
              :placeholder="$t('key-25')"
              @input="claim_amount = Number(claim_amount)"
            />
            <!-- <span class="text-danger">{{ errors[0] }}</span> -->
          <!-- </validation-provider> -->
        </b-form-group>

      </b-modal>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </validation-observer>
</template>

<script>
import {
  BTable, BLink,
  // BFormSelect,
  BModal, BFormGroup, BFormInput, BFormTextarea, BPagination, BOverlay,
} from 'bootstrap-vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

extend('positive', {
  validate: value => value > 0,
  message: 'กรุณากรอกจำนวนเงินให้ถูกต้อง',
})

export default {
  components: {
    BTable,
    BLink,
    // BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    AppTimeline,
    AppTimelineItem,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      message: '78708961999066',
      isActive: 'all',
      required,
      selected: null,
      filter: null,
      ID: null,
      claim_amount: 0,
      claim_detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',
      currentPage: 1,
      perPage: 10,
      search: '',
      imagePreviewUrls: '',

      itemarr: [],
      items: [],
      itemss: [
        {
          id: 1,
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'karayme68',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '22/07/2023',
            cargoTh: '27/07/2023',
            payFreight: '28/07/2023',
            removed: 'PAY-6918',
          },
          setup: 'P42',
          bill: 'PAY-6918',
          action: 'จัดการ',
        },

        {
          id: 2,
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'manit2708',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '22/07/2023',
            cargoTh: '27/07/2023',
            payFreight: '',
            removed: '',
          },
          setup: 'P42',
          bill: '',
          action: 'จัดการ',
        },

        {
          id: 3,
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'boycott01',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '',
            cargoTh: '',
            payFreight: '',
            removed: '',
          },
          setup: 'P42',
          bill: 'PAY-6918',
          action: 'จัดการ',
        },
      ],
      iconClass: {},
      showOver: false,
      search_val: null,
      totalRow: 0,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'detail', label: this.$t('details'), thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'customerCode', label: this.$t('customerCode'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'calPrice', label: this.$t('calculatePrice'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'tranStatus', label: this.$t('shippingStatus'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'setup', label: this.$t('arrange'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'bill', label: this.$t('issueBill'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getTracking()
    // this.getData('all')
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      // console.log(picture)
      // eslint-disable-next-line no-shadow
      picture.forEach(picture => {
        const img = { path: this.GetImg('postnumber_img', picture) }
        this.images.push(img)
      })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    async getTracking() {
      this.showOver = true
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          search_val: this.search_val,
        }
        this.$http.get('/Tracking/getpost', { params })
          .then(response => {
            // this.items = []
            // response.data.data.map(ele => {
            //   ele.path_img = []
            // })
            this.items = response.data.data
            // console.log(this.items)
            this.totalRow = response.data.total
            this.showOver = false
            // this.Showimg()
          })
        // this.itemss = res
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    Showimg() {
      try {
        const Id = []
        this.items.forEach(ele => { Id.push(ele._id) })
        const params = {
          id: Id,
        }
        this.$http.get('/Tracking/getDataIMG', { params })
          .then(response => {
            response.data.forEach((path_img, index) => {
              this.items[index].path_img = path_img.path_img
            })
          })
          .catch(err => console.log(err))
      } catch (e) {
        console.debug('ee', e)
      }
    },
    checkcharge_per(item) {
      if (item.charge_per_save === item.charge_per_kilo) {
        return this.$t('calculateByKilo')
      }
      return this.$t('key-98')
    },
    checktype(item) {
      if (item === 1) {
        return this.$t('truck')
      } if (item === 2) {
        return this.$t('ship')
      }
      return ''
    },
    chechproduct(item) {
      if (!item) {
        return '-'
      }
      if (this.$i18n.locale === 'th') {
        return item.pro_name
      } if (this.$i18n.locale === 'en') {
        return item.pro_name_en
      } if (this.$i18n.locale === 'cn') {
        return item.pro_name_cn
      }
      return item.pro_name
    },
    removeImage(index, ID) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบรูปสินค้าใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          const obj = {
            _id: ID,
            path_img: index,
          }
          this.$http.post('/Tracking/remove', obj)
            .then(() => {
              this.Success('ลบรูปสินค้าเรียบร้อย')
              this.getTracking()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          // this.delete_path = []
          // const indexToRemove = this.items.path_file.indexOf(index)
          // if (indexToRemove !== -1) {
          //   this.items.path_file.splice(indexToRemove, 1)
          //   this.items.path_file = null
          // }
        } else {
          console.log('เกิดข้อมูลผิดพลาด')
        }
      })
      // console.log(url)
      // console.log(index)
      // this.imagePreviewUrls = this.imagePreviewUrls.filter(item => item !== url)
    },
    getDataStorage() {
      const data = JSON.parse(localStorage.getItem('data'))
      this.imagePreviewUrl = data.imagePreviewUrl
      this.imagePreviewUrls = data.imagePreviewUrls
      this.colorBG = data.colorBG
      this.colorText = data.colorText
      this.gradientColor = data.gradientColor
    },
    handleFileChanges(event, ID) {
      const { files } = event.target
      // console.log(files)
      // this.imagePreviewUrls = []
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls),
      )
      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls = e.target.result
          const obj = {
            _id: ID,
            image: this.imagePreviewUrls,
          }
          this.$http.post('/Tracking/updateimg', obj)
            .then(() => {
              this.Success('เพิ่มรูปสินค้าเรียบร้อย')
              this.getTracking()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          // this.imagePreviewUrls.push(e.target.result)
          // console.log(this.imagePreviewUrls)
        }
        reader.readAsDataURL(files[i])
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    Claim(item) {
      this.$swal({
        title: '<h3 style="color: #141414">ทำรายการ Claim สินค้า</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: 'คุณต้องเคลมสินค้าหรือไม่ ?',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.$bvModal.show('modal-cliam')
          // eslint-disable-next-line no-underscore-dangle
          this.ID = item._id
          this.claim_detail = item.claim_detail
          this.claim_amount = item.claim_amount
          // this.getTracking()
          // this.$swal({
          //   icon: 'success',
          //   title: '<h3 style="color: #141414">สำเร็จ</h3>',
          //   html: '<p style="color: #141414">ทำรายการเคลมสินค้าสำเร็จ</p>',
          //   showConfirmButton: false,
          //   timer: 1500,
          // })
        }
      })
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const obj = {
            ID: this.ID,
            claim_detail: this.claim_detail,
            claim_amount: this.claim_amount,
          }
          // console.log(obj)
          this.$http.post('postnumber/claim', obj)
            .then(() => {
              this.getTracking()
              this.$bvToast.toast('ทำรายการเคลมสินค้าสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
              this.$bvModal.hide('modal-cliam')
            })
            .catch(() => {
              this.$bvToast.toast('ทำรายการเคลมสินค้าไม่สำเร็จ', {
                title: 'เกิดข้อผิดพลาด',
                variant: 'danger',
                solid: true,
                autoHideDelay: 1500,
              })
            })
        }
      })
    },
    // async getData(type) {
    //   this.isActive = type
    //   try {
    //     const { data: res } = await this.$http.get('/groupbyuser/list/index')
    //     this.items = res
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    EditType(data) {
      this.$router.push({ name: 'admin-tracking-edit', params: { id: data } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http.post('/Tracking/removePostnumber', params).then(() => {
              this.getTracking()
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCopy(item) {
      if (item) {
        this.$set(this.iconClass, item, true)

        setTimeout(() => {
          this.$set(this.iconClass, item, false)
        }, 3500)
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

            <style lang="scss" scoped></style>
